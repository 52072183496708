import playIcon from "../../assets/play-icon.svg";

interface PlayIconProps {
  color?: string;
  className?: string;
  onClick?: () => void;
}

function PlayIcon({ color, className, onClick }: PlayIconProps) {
  return (
    <button onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        className={className}
      >
        <path
          fill={color}
          d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32M7 6l8 4l-8 4z"
        />
      </svg>
    </button>
  );
}

export default PlayIcon;
